import React from "react";
import {
  Blur,
  Container,
  Illustration,
  PageWrapper,
} from "src/pages/WelcomeOnboarding/styles";
import QRCodeWelcome from "src/pages/WelcomeOnboarding/components/QRCodeWelcome";
import illustration from "src/assets/images/app_preview.webp";
import SessionPageLayout from "src/components/SessionPageLayout";
import { useGetMemberQuery } from "src/api/main";
import Loading from "src/components/Loading";
import { isMobile } from "src/utils/isMobile";

const WelcomeOnboarding = () => {
  const { data: member, isFetching } = useGetMemberQuery();

  if (isFetching) {
    return <Loading />;
  }

  return (
    <SessionPageLayout memberInfo={member} style={{ padding: 0 }}>
      <Container>
        <PageWrapper>
          <QRCodeWelcome />
          {isMobile() && <Blur />}
          <Illustration src={illustration} alt="app_illustration" />
        </PageWrapper>
      </Container>
    </SessionPageLayout>
  );
};

export default WelcomeOnboarding;
