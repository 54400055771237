import { styled } from "@mui/material";
import theme, { DESKTOP_MOCKUP_HEIGHT, getResponsiveValue } from "src/theme";
import Typography from "../../Typography";
import { Button } from "ritual-ui";

export const Wrapper = styled("div")({
  padding: "20px",
  display: "none",
  flexDirection: "column",
  alignItems: "flex-start",
  position: "absolute",
  top: getResponsiveValue(160, DESKTOP_MOCKUP_HEIGHT),
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 100,
  background: theme.palette.primary.light,
  [theme.breakpoints.down("md")]: {
    display: "flex",
  },
});

export const Info = styled("div")({
  display: "flex",
  alignItems: "center",
  marginBottom: "32px",
});

export const MemberPicture = styled("img")({
  width: "48px",
  height: "48px",
  borderRadius: "50%",
  marginRight: "16px",
});

export const MemberName = styled(Typography)({
  fontWeight: 500,
  whiteSpace: "break-spaces",
  textAlign: "center",
  fontFamily: "Noto Serif",
  fontSize: "20px",

  [theme.breakpoints.down("sm")]: {
    fontSize: "18px",
    color: theme.palette.primary.dark,
  },
});

export const LogoutButton = styled(Button)({
  borderRadius: "20px",
  padding: "8px 70px",
  width: "auto",
});

export const LogoutButtonText = styled("p")({
  fontFamily: "Avenir Next",
  fontWeight: 500,
  color: theme.palette.primary.main,
  fontSize: "16px",
  lineHeight: "24px",
});

export const CloseButton = styled("div")({
  position: "absolute",
  right: "20px",
  zIndex: 1000000,
  paddingTop: "12px",
});
