import React, { useEffect } from "react";
import {
  QRCodeContent,
  Wrapper,
  Title,
  Subtitle,
  Body,
} from "src/pages/WelcomeOnboarding/components/QRCodeWelcome/styles";
import QRCodeScreen from "src/components/QRCode";
import useGenerateShortLink from "src/hooks/useGenerateShortLink";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isMobile } from "src/utils/isMobile";
import { NativeAppDownloadButton } from "src/components/NativeAppDownloadButton";
import RitualAnalytics from "client-analytics";

const QRCodeWelcome = () => {
  const { showNativeAppQr } = useFlags();
  const shortLink = useGenerateShortLink();

  useEffect(() => {
    if (shortLink && !isMobile()) {
      RitualAnalytics.track("member_QR_generated", {
        application: showNativeAppQr ? "native" : "webapp",
      });
    }
  }, [shortLink]);

  return (
    <Wrapper>
      <QRCodeContent>
        <Title>Here you go!</Title>
        <Subtitle>
          Your journey and support towards a stronger, healthier relationship
          begins here.
        </Subtitle>
        <Body>
          {isMobile()
            ? "Please download our app and login to continue."
            : "OurRitual is a mobile platform. Please download our app and login to continue."}
        </Body>
        {isMobile() ? (
          <NativeAppDownloadButton />
        ) : (
          <QRCodeScreen url={shortLink} size={220} showCaption />
        )}
      </QRCodeContent>
    </Wrapper>
  );
};

export default QRCodeWelcome;
