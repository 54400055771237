import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import QRCode from "react-qr-code";
import { Wrapper } from "src/components/QRCode/styles";
import Typography from "../Typography";

interface QRCodeProps {
  url: string;
  size?: number;
  showCaption?: boolean;
}

const QRCodeScreen = ({ url, size, showCaption }: QRCodeProps) => {
  const { showNativeAppQr } = useFlags();

  const nativeAppStoresLink = `${process.env.REACT_APP_NATIVE_APP_STORE_LINK}`;

  return (
    <>
      <Wrapper>
        <QRCode
          value={showNativeAppQr ? nativeAppStoresLink : url}
          size={size || 300}
        />
      </Wrapper>
      {showCaption && (
        <Typography
          color="#A79FA4"
          variant="body2"
          weight={500}
          style={{ marginTop: "16px" }}
        >
          Scan the QR code to download the app
        </Typography>
      )}
    </>
  );
};

export default QRCodeScreen;
