import styled from "@emotion/styled";
import { Typography } from "src/components";
import theme from "src/theme";

export const Wrapper = styled("div")({
  display: "flex",
  maxWidth: "600px",
  padding: "48px 40px",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: 24,
  backgroundColor: "#FFF",

  [theme.breakpoints.down("lg")]: {
    position: "absolute",
    bottom: 20,
    width: "calc(100% - 40px)",
    zIndex: 10,
    padding: "24px 16px",
    borderRadius: 16,
    maxWidth: "100%",
  },
});

export const QRCodeContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  span: {
    color: theme.palette.primary.main,
  },
});

export const Footer = styled("div")({
  display: "flex",
  gap: "8px",
});

export const Title = styled(Typography)({
  color: "#31232F",
  fontFamily: "Noto Serif",
  fontSize: "48px",

  fontWeight: 500,
  lineHeight: "130%",
  letterSpacing: "-0.48px",
  textTransform: "capitalize",

  [theme.breakpoints.down("lg")]: {
    fontSize: "24px",

    fontWeight: 600,
    lineHeight: "150%",
    textTransform: "capitalize",
  },
});

export const Subtitle = styled(Typography)({
  color: "#644F5D",
  textAlign: "center",
  fontFamily: "Avenir Next",
  fontSize: "20px",
  fontWeight: 400,
  lineHeight: "150%",
  letterSpacing: "-0.2px",

  marginTop: "16px",

  [theme.breakpoints.down("lg")]: {
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "-0.16px",
  },
});

export const Body = styled(Typography)({
  color: "#644F5D",
  textAlign: "center",
  fontFamily: "Avenir Next",
  fontSize: "18px",
  fontWeight: 400,
  lineHeight: "150%",
  letterSpacing: "-0.18px",
  marginTop: "100px",
  marginBottom: "16px",

  [theme.breakpoints.down("lg")]: {
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "-0.16px",
    marginTop: "24px",
    marginBottom: "24px",
  },
});
