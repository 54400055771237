import { SessionStatus } from "src/api/types";

import CanceledSession from "./CanceledSession";
import { EndStatus } from "./CanceledSession/utils";
import SessionNotStarted from "./SessionNotStarted";

export const pageContent = {
  [SessionStatus.ONGOING]: {
    Component: SessionNotStarted,
  },

  [SessionStatus.ENDED]: {
    Component: () => <CanceledSession endStatus={EndStatus.COMPLETED} />,
  },

  [SessionStatus.SCHEDULED]: {
    Component: SessionNotStarted,
  },

  [SessionStatus.CANCELED]: {
    Component: () => <CanceledSession endStatus={EndStatus.CANCELLED} />,
  },
  [SessionStatus.RESCHEDULED]: {
    Component: SessionNotStarted,
  },
  [SessionStatus.NOT_STARTED]: {
    Component: SessionNotStarted,
  },
};
