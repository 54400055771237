import { useMemo } from "react";
import { useGetSessionDetailsQueryResolver } from "src/api/resolvers";
import Loading from "src/components/Loading";
import InvalidSession from "./SessionStates/InvalidSession";
import { ErrorStatus } from "./SessionStates/InvalidSession/utils";
import { pageContent } from "./SessionStates/pageContent";

const JoinSession = () => {
  const { data: session, isFetching } = useGetSessionDetailsQueryResolver();

  const content = useMemo(
    () => session && pageContent[session.status],
    [session],
  );

  if (isFetching) {
    return <Loading />;
  }

  if (content?.Component) {
    const Component = content.Component;
    return <Component />;
  }

  return <InvalidSession errorStatus={ErrorStatus.INVALID_LINK} />;
};

export default JoinSession;
