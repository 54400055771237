import styled from "@emotion/styled";
import theme from "src/theme";
import backgroundIllustration from "src/assets/images/onboarding_welcome.svg";

export const Container = styled("div")({
  display: "flex",
  width: "100%",
  height: "95%",
  margin: "auto 0",
  padding: "0 75px",

  backgroundImage: `url(${backgroundIllustration})`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right",

  [theme.breakpoints.down("lg")]: {
    marginTop: "100px",
    padding: "0 20px",
    alignItems: "flex-start",
    backgroundPosition: "top",
  },
});

export const PageWrapper = styled("div")({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  maxWidth: "1800px",
  margin: "0 auto",
  justifyContent: "space-between",
  alignItems: "center",

  [theme.breakpoints.down("lg")]: {
    alignItems: "flex-start",
    overflow: "hidden",
  },
});

export const Illustration = styled("img")({
  height: "80%",
  maxHeight: "800px",
  objectFit: "contain",

  [theme.breakpoints.down("xl")]: {
    maxHeight: "600px",
  },

  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
});

export const Blur = styled("div")({
  width: "100%",
  height: "260px",
  position: "absolute",
  zIndex: 1,
  bottom: "220px",
  left: 0,
  background:
    "linear-gradient(180deg, rgba(238, 236, 224, 0.00) 30.85%, #EEECE0 65.32%)",
});
